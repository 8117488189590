/**
 * Add Seeker Reducer
 */
import {
    ADD_SEEKER_SHOW_MODAL,
    ADD_SEEKER_HIDE_MODAL,
    ADD_SEEKER_FOR_POSITION,
    ADD_SEEKER_FOR_POSITION_SUCCESS,
    ADD_SEEKER_FOR_POSITION_FAILURE,
    ADD_SEEKER_FOR_POSITION_EXCEEDED,
    ADD_SEEKER_FOR_POSITION_UNAVAILABLE,
    ADD_SEEKER_HIDE_FAILURE_POPUP,
    ADD_SEEKER_OVER_18,
    ADD_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS
} from 'Actions/types';

// initial state
const INIT_STATE = {
    showAddSeeker: false,
    lastAddedId: null,
    listSeeker: [],
    nextPageAvailable: true,
    onLoading: false,
    onLoadNextPage: false,
    onAdding: false,
    addSuccess: false,
    addFailure: false,
    addGotExceeded: false,
    currentPositionId: null,
    addGotUnavailable: false,
    addSeekerForOver18: false,
    addFailure48h: false,
    addSeekerErrorMsg: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_SEEKER_SHOW_MODAL:
            return { ...state, showAddSeeker: true, currentPositionId: action.payload };

        case ADD_SEEKER_HIDE_MODAL:
            return { ...state, showAddSeeker: false, listSeeker: [] };

        case ADD_SEEKER_FOR_POSITION:
            return { ...state, onAdding: true, lastAddedId: action.payload.seekerId };

        case ADD_SEEKER_FOR_POSITION_SUCCESS:
            return { ...state, onAdding: false, addSuccess: true };

        case ADD_SEEKER_FOR_POSITION_FAILURE:
            return { ...state, onAdding: false, addFailure: true, addSeekerErrorMsg: action.payload};

        case ADD_SEEKER_FOR_POSITION_EXCEEDED:
            return { ...state, onAdding: false, addGotExceeded: true };

        case ADD_SEEKER_FOR_POSITION_UNAVAILABLE:
            return { ...state, onAdding: false, addGotUnavailable: true };

        case ADD_SEEKER_HIDE_FAILURE_POPUP:
            return { ...state, addFailure: false, addSuccess: false, addGotExceeded: false, addGotUnavailable: false, addSeekerForOver18:false, addFailure48h: false };
        case ADD_SEEKER_OVER_18:
            return { ...state, onAdding: false, addSeekerForOver18: true };

        case ADD_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS:
            return { ...state, onAdding: false, addFailure48h: true};

        default: return { ...state };
    }
};
