import ApiConfig from './ApiConfig';
import Sidebar1 from "Assets/img/sidebar-1.png";

/**
 * App Config File
 */
const AppConfig = {
    apiURL: ApiConfig.BaseURL,
    secureLocalStorage: {
        encryptionSecret: process.env.ENCRYPTION_SECRET,
        isCompression: false, // not compress data to handle Detecttive String
        encodingType: 'aes'
    },
    headers: {
        accept: 'application/vnd.omnimywork.v3+json',
    },
    appVersionNumber: '1.9.2',
    appBuildNumber: '1',
    brandName: 'GIG',                                    // Brand Name
    navCollapsed: false,                                      // Sidebar collapse
    darkMode: false,                                          // Dark Mode
    boxLayout: false,                                         // Box Layout
    rtlLayout: false,                                         // RTL Layout
    miniSidebar: false,                                       // Mini Sidebar
    enableSidebarBackgroundImage: true,                       // Enable Sidebar Background Image
    sidebarImage: Sidebar1,     // Select sidebar image
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'en',
    },
    timezone: 'Europe/London',
    enableUserTour: true,                                      // Enable / Disable User Tour
    copyRightText: 'GIG APP LTD © All Rights Reserved.',      // Copy Right Text

    // light theme colors
    themeColors: {
        'red'        : 'red',
        'primary'    : '#5D92F4',
        'secondary'  : '#677080',
        'success'    : '#00D014',
        'danger'     : '#FF3739',
        'warning'    : '#FFB70F',
        'info'       : '#00D0BD',
        'dark'       : '#000',
        'default'    : '#FAFAFA',
        'greyLighten': '#A5A7B2',
        'grey'       : '#677080',
        'white'      : '#FFFFFF',
        'purple'     : '#896BD6',
        'yellow'     : '#D46B08',
        'baseOrange' : '#F7AF42',
        'otherOrange': '#FFAF4C',
        'black'      : '#000',
        'transparent': 'transparent',
        'orange'     : '#F8b033'
    },
    appColors: {
        'purple'   : '#994d99',
        'orange'   : '#f7af42',
        'turquoise': '#6cbdc4',
        'salmon'   : '#e26a5c',
        'green'    : '#40c27c',
        'black'      : '#000',
    },
    // dark theme colors
    darkThemeColors: {
        darkBgColor: '#424242'
    },

    /**
     * Define app's font used Museosans font
     */
    fontWeight: {
        bolder: 'museosans-900',
        bold  : 'museosans-700',
        normal: 'museosans-500',
        thin  : 'museosans-300',
        thiner: 'museosans-100',
    },

    /**
     * Minimum time of job & timecard
     */
    jobMinDuration: {
        minutes: 60,
        hours: 1
    },

    /**
     * Maximum time of job & timecard
     */
    jobMaxDuration: {
        minutes: 1080,
        hours: 18
    },

    /**
     * The minimum time to require check password
     *
     * Usage
     *   - Timesheet: Approve remote requests
     *
     * @type {number} The seconds
     */
    minSecondsCheckPassword: 900,

    /**
     * Flyer app key
     */
    appsFlyerDevKey: 'a1e51ee85e8aa74d1320',

    pusherKey:        process.env.PUSHER_KEY,
    /**
     * Google map API key & configurations
     */
    GoogleMaps:{
        API_KEY: process.env.REACT_APP_GOOGLE_MAP_API,
        GEOCODER_LOCATION_TYPE: {
            'APPROXIMATE': 1,
            'GEOMETRIC_CENTER': 2,
            'RANGE_INTERPOLATED': 3,
            'ROOFTOP': 4
        }
    },

    StripeKey: {
        API_KEY: process.env.REACT_APP_STRIPLE_KEY
    },

    InstantSearch: {
        appId : process.env.INSTANT_SEARCH_ID,
        apiKey: process.env.INSTANT_SEARCH_KEY
    },
    //BUCKET: "s3employeeimage",

    /**
     * External links
     */
    URL_TERMS_AND_CONDITIONS: process.env.URL_TERMCONDITION,
    URL_PRIVACY_POLICY: process.env.URL_PRIVACY_POLICY,
    URL_TOP_TIPS: process.env.URL_TOP_TIPS,

     URL_ADMIN_PANEL: process.env.URL_ADMIN_PANEL,
     URL_GIG_ADMIN: process.env.REACT_APP_URL_GIG_ADMIN,
    /**
     * S3 Config
     */
    BUCKET: process.env.S3_BUCKET,
    URL_APP: process.env.URL_APP,

    S3_ACESS_KEY: process.env.S3_ACESS_KEY,
    S3_ACESS_KEY: process.env.S3_SECRET_KEY,
    S3_ACESS_KEY: process.env.S3_REGION,

    SENTRY_DNS: process.env.SENTRY_DNS,
    SENTRY_ENV: process.env.APP_ENV,
};

export default AppConfig;
