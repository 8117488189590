/**
 * Shift App Reducer
 */

import {
    CREATE_NEW_SHIFT,
    CREATE_NEW_SHIFT_SUCCESS,
    CREATE_NEW_SHIFT_FAILURE,
    EDIT_SHIFT,
    EDIT_SHIFT_SUCCESS,
    EDIT_SHIFT_FAILURE,
    SHOW_OVERLAPPED_POPUP
} from "Actions/types";

// initial state
const INIT_STATE = {
    newShift: null,
    editedShift: null,
    loading: false,
    overlappedSeeker: [],
    checkOverlappedDone: false,
    successAlert: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_NEW_SHIFT:
            return { ...state, successAlert: false };

        case CREATE_NEW_SHIFT_SUCCESS:
            return { ...state, newShift: action.payload, successAlert: true };

        case CREATE_NEW_SHIFT_FAILURE:
            return { ...state };

        case EDIT_SHIFT:
            return { ...state, successAlert: false };

        case EDIT_SHIFT_SUCCESS:
            return { ...state, editedShift: action.payload, successAlert: true };

        case EDIT_SHIFT_FAILURE:
            return { ...state };

        case SHOW_OVERLAPPED_POPUP:
            return { ...state, overlappedSeeker: action.payload.params.data.data, checkOverlappedDone: true };

        default:
            return { ...state };
    }
};
