import API from 'Api';
import StoredData from "Constants/StoredData";
/**
 * get employer location
 * @return async
 */

export const getLocationsRequest = async () => {
  const params= {user_level_id: Number(StoredData.get(StoredData.KEYS.EMPLOYER_LEVEL_ID))}
  return await API.get('/employers/locations', params)
  .then(response => response)
  .catch(err => err);
} 


/**
 * Edit location
 * @param  {Object} location_params
 * @typedef {{
        building            : number,
        city                : string,
        id                  : number,
        latitude            : number,
        longitude           : number,
        name                : string,
        street              : number
        postcode            : number,
    }}
 * @return async
 */

export const editLocationRequest = async location_params => await API.put(`/employers/locations/${location_params.id}`, location_params);


/**
 * create location
 * @param  {Object} location_params
 * @typedef {{
        building            : number,
        city                : string,
        latitude            : number,
        longitude           : number,
        name                : string,
        street              : number
        postcode            : number,
    }}
 * @return async
 */
export const createLocationRequest = async location_params => await API.post('/employers/locations', location_params);


/**
 * remove location
 * @param  {Object} location_params
 * @typedef {{
        locationId           : number,
    }}
 * @return async
 */
export const removeLocationRequest = async locationId => await API.remove(`/employers/locations/${locationId}`);
