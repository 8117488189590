import firebaseConfig from 'Helpers/FirebaseConfig'
import * as firebase from  'firebase';



firebase.initializeApp(firebaseConfig);


export default firebase;

