/**
 * App Redux Action Types
 */
export const SHOW_FORM_GPDR        = 'SHOW_FORM_GPDR';
export const HIDE_FORM_GPDR        = 'HIDE_FORM_GPDR';
export const STORE_DATA_PROTECTION = 'STORE_DATA_PROTECTION';
export const STORE_DATA_PROTECTION_SUCCESS = 'STORE_DATA_PROTECTION_SUCCESS';
export const STORE_DATA_PROTECTION_FAIL = 'STORE_DATA_PROTECTION_FAIL';
export const SHOW_ERROR_RESPONSE   = 'SHOW_ERROR_RESPONSE';
export const HIDE_ERROR_RESPONSE   = 'HIDE_ERROR_RESPONSE';
export const COLLAPSED_SIDEBAR     = 'COLLAPSED_SIDEBAR';
export const DARK_MODE             = 'DARK_MODE';
export const BOXED_LAYOUT          = 'BOXED_LAYOUT';
export const RTL_LAYOUT            = 'RTL_LAYOUT';
export const MINI_SIDEBAR          = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE    = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR    = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE  = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE     = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE          = 'SET_LANGUAGE';
export const START_USER_TOUR       = 'START_USER_TOUR';
export const STOP_USER_TOUR        = 'STOP_USER_TOUR';
export const TOGGLE_DARK_SIDENAV   = 'TOGGLE_DARK_SIDENAV';
export const APP_SHOW_ROOT_SPINNER = 'APP_SHOW_ROOT_SPINNER';
export const APP_HIDE_ROOT_SPINNER = 'APP_HIDE_ROOT_SPINNER';

// Header actions
export const SEARCH_JOB = 'SEARCH_JOB';
// reports
export const EXCEL_DOWNLOAD_REPORTS             = 'EXCEL_DOWNLOAD_REPORTS';
export const EXCEL_DOWNLOAD_FULFILMENT_REPORTS  = 'EXCEL_DOWNLOAD_FULFILMENT_REPORTS';
export const EXCEL_DOWNLOAD_BILLING_REPORTS     = 'EXCEL_DOWNLOAD_BILLING_REPORTS';
export const EXCEL_DOWNLOAD_AWR_REPORTS         = 'EXCEL_DOWNLOAD_AWR_REPORTS';

// ecommerce
export const ON_DELETE_ITEM_FROM_CART = 'ON_DELETE_ITEM_FROM_CART';
export const ON_QUANTITY_CHANGE = 'ON_QUANTITY_CHANGE';
export const ON_ADD_ITEM_TO_CART = 'ON_ADD_ITEM_TO_CART';

// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';

// Auth
export const POST_REAUTHORISE            = 'POST_REAUTHORISE';
export const POST_REAUTHORISE_SUCCESS    = 'POST_REAUTHORISE_SUCCESS';
export const POST_REAUTHORISE_FAILURE    = 'POST_REAUTHORISE_FAILURE';
export const LOGIN_USER             = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS     = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE     = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER            = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS    = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE    = 'LOGOUT_USER_FAILURE';
export const CHECK_EMAIL_VERIFIED         = 'CHECK_EMAIL_VERIFIED';
export const CHECK_EMAIL_VERIFIED_SUCCESS = 'CHECK_EMAIL_VERIFIED_SUCCESS';
export const CHECK_EMAIL_VERIFIED_FAILURE = 'CHECK_EMAIL_VERIFIED_FAILURE';
export const GET_EMPLOYER_DETAIL          = 'GET_EMPLOYER_DETAIL';
export const GET_EMPLOYER_DETAIL_SUCCESS  = 'GET_EMPLOYER_DETAIL_SUCCESS';
export const GET_EMPLOYER_DETAIL_FAILURE  = 'GET_EMPLOYER_DETAIL_FAILURE';
export const REMOVE_REDUCER               = 'REMOVE_REDUCER';
export const CHECK_EMPLOYER_EMAIL_EXIST   = 'CHECK_EMPLOYER_EMAIL_EXIST';
export const CHECK_EMPLOYER_EMAIL_EXIST_SUCCESS   = 'CHECK_EMPLOYER_EMAIL_EXIST_SUCCESS';
export const CHECK_EMPLOYER_EMAIL_EXIST_FAIL   = 'CHECK_EMPLOYER_EMAIL_EXIST_FAIL';

// call api shift detail for step 3
export const GET_SHIFT_OPTIONS = 'GET_SHIFT_OPTIONS';
export const GET_SHIFT_OPTIONS_SUCCESS = 'GET_SHIFT_OPTIONS_SUCCESS';
export const GET_SHIFT_OPTIONS_FAIL = 'GET_SHIFT_OPTIONS_FAIL';

// reset prop of sign up form when unmount
export const RESET_PROPS_SIGN_UP = 'RESET_PROPS_SIGN_UP';
export const RESET_EMAIL_TRACKING_SIGN_UP = 'RESET_EMAIL_TRACKING_SIGN_UP';

export const CHECK_ACCESS_CODE_RESET_PROCESS = 'CHECK_ACCESS_CODE_RESET_PROCESS';
export const CHECK_ACCESS_CODE               = 'CHECK_ACCESS_CODE';
export const CHECK_ACCESS_CODE_VALID         = 'CHECK_ACCESS_CODE_VALID';
export const CHECK_ACCESS_CODE_INVALID       = 'CHECK_ACCESS_CODE_INVALID';
export const CHECK_ACCESS_CODE_FAILURE       = 'CHECK_ACCESS_CODE_FAILURE';

export const SIGN_UP_USER                 = 'SIGN_UP_USER';
export const SIGN_UP_USER_SUCCESS         = 'SIGN_UP_USER_SUCCESS';
export const SIGN_UP_USER_FAILURE         = 'SIGN_UP_USER_FAILURE';
export const SIGN_UP_RESET_STATUS         = 'SIGN_UP_RESET_STATUS';
export const ON_CHANGE_USER_REGISTER      = 'ON_CHANGE_USER_REGISTER';
export const GET_SHIFT_OPTION             = 'GET_SHIFT_OPTION';

// Forgot Password

export const RESET_TEXT_FORGOT = 'RESET_TEXT_FORGOT';

export const CHECK_EMAIL_EXIST = 'CHECK_EMAIL_EXIST';
export const CHECK_EMAIL_EXIST_SUCCESS = 'CHECK_EMAIL_EXIST_SUCCESS';
export const CHECK_EMAIL_EXIST_FAILURE = 'CHECK_EMAIL_EXIST_FAILURE';

export const SEND_FORGOT_PASSWORD = 'SEND_FORGOT_PASSWORD';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const SEND_FORGOT_PASSWORD_FAILURE = 'SEND_FORGOT_PASSWORD_FAILURE';

// positon
export const OPEN_ACCEPT_POPUP              = 'OPEN_ACCEPT_POPUP'
export const OPEN_DECLINE_POPUP             = 'OPEN_DECLINE_POPUP';
export const OPEN_DECLINE_ASSIGNMENT_POPUP  = 'OPEN_DECLINE_ASSIGNMENT_POPUP';
export const OPEN_CHECKIN_OUT_POPUP         = 'OPEN_CHECKIN_OUT_POPUP';
export const SEND_CURRENT_POSITION        = 'SEND_CURRENT_POSITION';
export const CLOSE_ALL_POPUPS             = 'CLOSE_ALL_POPUPS';
export const CHECK_OVERLAPS               = 'CHECK_OVERLAPS';
export const IS_OVERLAPS                  = 'IS_OVERLAPS';
export const NOT_OVERLAPS                 = 'NOT_OVERLAPS';
export const ACCEPT_CANDIDATE             = 'ACCEPT_CANDIDATE';
export const ACCEPT_CANDIDATE_SUCCESS     = 'ACCEPT_CANDIDATE_SUCCESS';
export const ACCEPT_CANDIDATE_FAIL        = 'ACCEPT_CANDIDATE_FAIL';

export const DECLINE_ASSIGNMENT               = 'DECLINE_ASSIGNMENT';
export const DECLINE_ASSIGNMENT_SUCCESS       = 'DECLINE_ASSIGNMENT_SUCCESS';
export const DECLINE_ASSIGNMENT_FAIL          = 'DECLINE_ASSIGNMENT_FAIL';

export const CHECK_IN_ASSIGNMENT               = 'CHECK_IN_ASSIGNMENT';
export const CHECK_IN_ASSIGNMENT_SUCCESS       = 'CHECK_IN_ASSIGNMENT_SUCCESS';
export const CHECK_IN_ASSIGNMENT_FAIL          = 'CHECK_IN_ASSIGNMENT_FAIL';

export const CHECK_OUT_ASSIGNMENT               = 'CHECK_OUT_ASSIGNMENT';
export const CHECK_OUT_ASSIGNMENT_SUCCESS       = 'CHECK_OUT_ASSIGNMENT_SUCCESS';
export const CHECK_OUT_ASSIGNMENT_FAIL          = 'CHECK_OUT_ASSIGNMENT_FAIL';

export const DECLINE_CANDIDATE            = 'DECLINE_CANDIDATE';
export const DECLINE_CANDIDATE_SUCCESS    = 'DECLINE_CANDIDATE_SUCCESS';
export const DECLINE_CANDIDATE_FAIL       = 'DECLINE_CANDIDATE_FAIL';

export const OPEN_NO_SHOW                 = 'OPEN_NO_SHOW';
export const NO_SHOW                      = 'NO_SHOW';
export const NO_SHOW_SUCCESS              = 'NO_SHOW_SUCCESS';
export const NO_SHOW_FAIL                 = 'NO_SHOW_FAIL';

// Jobs

/**
 * For update posted and filled people in job card
 * after accept/decline candidate, decline assignment, add seeker success
*/

export const UPDATE_NUMBER_OF_SEEKER = 'UPDATE_NUMBER_OF_SEEKER';

export const SET_JOB_FORM           = 'SET_JOB_FORM';

export const OPEN_CREATE_JOB_MODAL  = 'OPEN_CREATE_JOB_MODAL';
export const CLOSE_CREATE_JOB_MODAL = 'CLOSE_CREATE_JOB_MODAL';

export const OPEN_EDIT_JOB          = 'OPEN_EDIT_JOB';
export const CLOSE_EDIT_JOB         = 'CLOSE_EDIT_JOB';

export const OPEN_REPOST_JOB        = 'OPEN_REPOST_JOB';
export const CLOSE_REPOST_JOB       = 'CLOSE_REPOST_JOB';
export const GET_UPCOMING_JOBS_SUCCESS                  = 'GET_UPCOMING_JOBS_SUCCESS';
export const GET_UPCOMING_JOBS                          = 'GET_UPCOMING_JOBS';
export const GET_UPCOMING_JOBS_FAILURE                  = 'GET_UPCOMING_JOBS_FAILURE';
export const UPDATE_UPCOMING_JOBS                       = 'UPDATE_UPCOMING_JOBS';
export const GET_POSTED_JOBS                            = 'GET_POSTED_JOBS';
export const GET_POSTED_JOBS_SUCCESS                    = 'GET_POSTED_JOBS_SUCCESS';
export const GET_POSTED_JOBS_FAILURE                    = 'GET_POSTED_JOBS_FAILURE';
export const GET_FILLED_JOBS                            = 'GET_FILLED_JOBS';
export const GET_FILLED_JOBS_SUCCESS                    = 'GET_FILLED_JOBS_SUCCESS';
export const GET_FILLED_JOBS_FAILURE                    = 'GET_FILLED_JOBS_FAILURE';
export const GET_COMPLETED_JOBS                         = 'GET_COMPLETED_JOBS';
export const GET_COMPLETED_JOBS_SUCCUESS                = 'GET_COMPLETED_JOBS_SUCCUESS';
export const GET_COMPLETED_JOBS_FAILURE                 = 'GET_COMPLETED_JOBS_FAILURE';
export const GET_PENDING_JOBS                           = 'GET_PENDING_JOBS';
export const GET_PENDING_JOBS_SUCCESS                   = 'GET_PENDING_JOBS_SUCCESS';
export const GET_PENDING_JOBS_FAILURE                   = 'GET_PENDING_JOBS_FAILURE';
export const GET_JOB_INFORMATION_BY_ID                  = 'GET_JOB_INFORMATION_BY_ID';
export const GET_JOB_INFORMATION_BY_ID_SUCCESS          = 'GET_JOB_INFORMATION_BY_ID_SUCCESS';
export const GET_JOB_INFORMATION_BY_ID_FAILURE          = 'GET_JOB_INFORMATION_BY_ID_FAILURE';
export const CLOSE_JOB_INFORMATION                      = 'CLOSE_JOB_INFORMATION';
export const GET_JOB_MORE_INFO                          = 'GET_JOB_MORE_INFO'
export const GET_JOB_MORE_INFO_SUCCESS                  = 'GET_JOB_MORE_INFO_SUCCESS'
export const GET_JOB_MORE_INFO_FAILURE                  = 'GET_JOB_MORE_INFO_FAILURE'
export const GET_JOB_BY_ID                              = 'GET_JOB_BY_ID';
export const GET_JOB_BY_ID_SUCCESS                      = 'GET_JOB_BY_ID_SUCCESS';
export const GET_JOB_BY_ID_FAILURE                      = 'GET_JOB_BY_ID_FAILURE';
export const GET_CALENDAR_JOBS                          = 'GET_CALENDAR_JOBS';
export const ADD_CALENDAR_JOBS_SUCCESS                  = 'ADD_CALENDAR_JOBS_SUCCESS';
export const GET_CALENDAR_JOBS_SUCCESS                  = 'GET_CALENDAR_JOBS_SUCCUESS';
export const GET_CALENDAR_JOBS_FAILURE                  = 'GET_CALENDAR_JOBS_FAILURE';
export const GET_ALL_JOBS                               = 'GET_ALL_JOBS';
export const GET_ALL_JOBS_SUCCESS                       = 'GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_FAILURE                       = 'GET_ALL_JOBS_FAILURE';
export const UPDATE_PROMOTED_STATUS                     = 'UPDATE_PROMOTED_STATUS';
export const UPDATE_PROMOTED_STATUS_SUCCESS             = 'UPDATE_PROMOTED_STATUS_SUCCESS';
export const UPDATE_JOB_AFTER_EDITED                    = 'UPDATE_JOB_AFTER_EDITED';
export const ADD_JOB_AFTER_CREATED                      = 'ADD_JOB_AFTER_CREATED';
export const REMOVE_JOB_FROM_LIST                       = 'REMOVE_JOB_FROM_LIST';
export const UPDATE_JOB_CALENDAR_AFTER_EDIT             = "UPDATE_JOB_CALENDAR_AFTER_EDIT";


export const GET_NUDGABLE_SEEKER_FOR_POSITION         = 'GET_NUDGABLE_SEEKER_FOR_POSITION';
export const GET_NUDGABLE_SEEKER_FOR_POSITION_SUCCESS = 'GET_NUDGABLE_SEEKER_FOR_POSITION_SUCCESS';
export const GET_NUDGABLE_SEEKER_FOR_POSITION_FAILURE = 'GET_NUDGABLE_SEEKER_FOR_POSITION_FAILURE';

export const CANCEL_JOB                 = 'CANCEL_JOB';
export const CANCEL_JOB_SUCCESS         = 'CANCEL_JOB_SUCCESS';
export const CANCEL_JOB_FAILURE         = 'CANCEL_JOB_FAILURE';

// Get candidates by position
export const GET_CANDIDATES_BY_POSITION         = 'GET_CANDIDATES_BY_POSITION';
export const GET_CANDIDATES_BY_POSITION_SUCCESS = 'GET_CANDIDATES_BY_POSITION_SUCCESS';
export const GET_CANDIDATES_BY_POSITION_FAILURE = 'GET_CANDIDATES_BY_POSITION_FAILURE';

// Get declined candidates by position
export const GET_DECLINED_CANDIDATES_BY_POSITION         = 'GET_DECLINED_CANDIDATES_BY_POSITION';
export const GET_DECLINED_CANDIDATES_BY_POSITION_SUCCESS = 'GET_DECLINED_CANDIDATES_BY_POSITION_SUCCESS';
export const GET_DECLINED_CANDIDATES_BY_POSITION_FAILURE = 'GET_DECLINED_CANDIDATES_BY_POSITION_FAILURE';

// Get assignments by position
export const GET_ASSIGNMENTS_BY_POSITION         = 'GET_ASSIGNMENTS_BY_POSITION';
export const GET_ASSIGNMENTS_BY_POSITION_SUCCESS = 'GET_ASSIGNMENTS_BY_POSITION_SUCCESS';
export const GET_ASSIGNMENTS_BY_POSITION_FAILURE = 'GET_ASSIGNMENTS_BY_POSITION_FAILURE';

// Check all assignments are checked in all or checked out all
export const CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN           = 'CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN';
export const CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_SUCCESS   = 'CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_SUCCESS';
export const CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_FAILURE   = 'CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_IN_FAILURE'  ;

// Check all assignments are checked out all or checked out all
export const CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT           = 'CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT';
export const CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_SUCCESS   = 'CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_SUCCESS';
export const CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_FAILURE   = 'CHECK_ALL_ASSIGNMENTS_ARE_CHECKED_OUT_FAILURE'  ;

// Employee
export const GET_EMPLOYEE                 = 'GET_EMPLOYEE';
export const GET_EMPLOYEE_SUCCESS         = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAILURE         = 'GET_EMPLOYEE_FAILURE';

//Seeker info
export const GET_INFO_SEEKER_IN_POSITION                 = 'GET_INFO_SEEKER_IN_POSITION';
export const GET_INFO_SEEKER_IN_POSITION_SUCCESS         = 'GET_INFO_SEEKER_IN_POSITION_SUCCESS';
export const GET_INFO_SEEKER_IN_POSITION_FAILURE         = 'GET_INFO_SEEKER_IN_POSITION_FAILURE';

// location
export const GET_LOCATION                   = 'GET_LOCATION';
export const GET_LOCATION_SUCCESS           = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE           = 'GET_LOCATION_FAILURE';
export const ADD_LOCATION                   = 'ADD_LOCATION';
export const ADD_LOCATION_SUCCESS           = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAILURE           = 'ADD_LOCATION_FAILURE';
export const EDIT_LOCATION                  = 'EDIT_LOCATION';
export const EDIT_LOCATION_SUCCESS          = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAILURE          = 'EDIT_LOCATION_FAILURE';
export const REMOVE_LOCATION                = 'REMOVE_LOCATION';
export const REMOVE_SUCCESS                 = 'REMOVE_SUCCESS';
export const REMOVE_FAILURE                 = 'REMOVE_FAILURE';
export const CONFIRM_ERROR_DELETE_POPUP     = 'CONFIRM_ERROR_DELETE_POPUP';
export const LOAD_MAP                       = 'LOAD_MAP';
export const INIT_STATE_LOCATION            = 'INIT_STATE_LOCATION';

// uniforms
export const GET_UNIFORMS         = 'GET_UNIFORMS';
export const GET_UNIFORMS_SUCCESS = 'GET_UNIFORMS_SUCCESS';
export const GET_UNIFORMS_FAILURE = 'GET_UNIFORMS_FAILURE';

// skills
export const GET_ALL_SKILLS     = 'GET_ALL_SKILLS';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAILURE = 'GET_SKILLS_FAILURE';

export const GET_ALL_SKILLS_BY_GROUP = 'GET_ALL_SKILL_BY_GROUP';
export const GET_ALL_SKILLS_BY_GROUP_SUCCESS = 'GET_ALL_SKILL_BY_GROUP_SUCCESS';
export const GET_ALL_SKILLS_BY_GROUP_FAIL = 'GET_ALL_SKILL_BY_GROUP_FAIL';

export const SET_FIELD_SKILL_FORM = 'SET_FIELD_SKILL_FORM';
export const SET_ERROR_TO_SKILL = 'SET_ERROR_TO_SKILL';

export const SEND_SKILLS_CHARGE_RATE = 'SEND_SKILLS_CHARGE_RATE';
export const SEND_SKILLS_CHARGE_RATE_SUCCESS = 'SEND_SKILLS_CHARGE_RATE_SUCCESS';
export const SEND_SKILLS_CHARGE_RATE_FAIL = 'SEND_SKILLS_CHARGE_RATE_FAIL';

export const HANDLE_CHANGE_MASTER_RATE = 'HANDLE_CHANGE_MASTER_RATE';
export const UPDATE_MASTER_RATE = 'UPDATE_MASTER_RATE';
export const UPDATE_MASTER_RATE_SUCCESS = 'UPDATE_MASTER_RATE_SUCCESS';
export const UPDATE_MASTER_RATE_FAIL = 'UPDATE_MASTER_RATE_FAIL';

export const CLOSE_ALL_ALERT = 'CLOSE_ALL_ALERT';

// get charge rate by pay rate
export const GET_CHARGERATE_BY_PAYRATE         = 'GET_CHARGERATE_BY_PAYRATE';
export const GET_CHARGERATE_BY_PAYRATE_SUCCESS = 'GET_CHARGERATE_BY_PAYRATE_SUCCESS';
export const GET_CHARGERATE_BY_PAYRATE_FAILURE = 'GET_CHARGERATE_BY_PAYRATE_FAILURE';
export const CHANGE_CHARGERATE                 = 'CHANGE_CHARGERATE';

//highlight position
export const TOGGLE_HIGHLIGHT_JOB              = 'TOGGLE_HIGHLIGHT_JOB';

// create shift/position
export const CREATE_NEW_SHIFT            = 'CREATE_NEW_SHIFT';
export const CREATE_NEW_SHIFT_SUCCESS    = 'CREATE_NEW_SHIFT_SUCCESS';
export const CREATE_NEW_SHIFT_FAILURE    = 'CREATE_NEW_SHIFT_FAILURE';
export const CREATE_NEW_POSITION         = 'CREATE_NEW_POSITION';
export const CREATE_NEW_POSITION_SUCCESS = 'CREATE_NEW_POSITION_SUCCESS';
export const CREATE_NEW_POSITION_FAILURE = 'CREATE_NEW_POSITION_FAILURE';

export const EDIT_SHIFT            = 'EDIT_SHIFT';
export const EDIT_SHIFT_SUCCESS    = 'EDIT_SHIFT_SUCCESS';
export const EDIT_SHIFT_FAILURE    = 'EDIT_SHIFT_FAILURE';
export const EDIT_POSITION         = 'EDIT_POSITION';
export const EDIT_POSITION_SUCCESS = 'EDIT_POSITION_SUCCESS';
export const EDIT_POSITION_FAILURE = 'EDIT_POSITION_FAILURE';
export const CHECK_OVERLAPPED      = 'CHECK_OVERLAPPED';
export const SHOW_OVERLAPPED_POPUP = 'SHOW_OVERLAPPED_POPUP';


// remote requests
export const GET_REMOTE_REQUEST_PENDING_LIST                = 'GET_REMOTE_REQUEST_PENDING_LIST';
export const GET_REMOTE_REQUEST_PENDING_DAILY_LIST          = 'GET_REMOTE_REQUEST_PENDING_DAILY_LIST';
export const GET_REMOTE_REQUEST_PENDING_GROUP_BY_DATE       = 'GET_REMOTE_REQUEST_PENDING_GROUP_BY_DATE';
export const GET_REMOTE_REQUEST_APPROVED_LIST               = 'GET_REMOTE_REQUEST_APPROVED_LIST';
export const GET_REMOTE_REQUEST_APPROVED_GROUP_BY_DATE      = 'GET_REMOTE_REQUEST_APPROVED_GROUP_BY_DATE';
export const GET_REMOTE_REQUEST_SUCCESS                     = 'GET_REMOTE_REQUEST_SUCCESS';
export const GET_REMOTE_REQUEST_FAILURE                     = 'GET_REMOTE_REQUEST_FAILURE';
export const GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE         = 'GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE';
export const ACCEPT_REMOTE_REQUEST                          = 'ACCEPT_REMOTE_REQUEST';
export const ACCEPT_LIST_REMOTE_REQUESTS                    = 'ACCEPT_LIST_REMOTE_REQUESTS';
export const DECLINE_REMOTE_REQUEST                         = 'DECLINE_REMOTE_REQUEST';
export const DECLINE_LIST_REMOTE_REQUESTS                   = 'DECLINE_LIST_REMOTE_REQUESTS';
export const ACCEPT_REMOTE_REQUESTS_SUCCESS                 = 'ACCEPT_REMOTE_REQUESTS_SUCCESS';
export const ACCEPT_REMOTE_REQUESTS_FAILURE                 = 'ACCEPT_REMOTE_REQUESTS_FAILURE';
export const DECLINE_REMOTE_REQUESTS_SUCCESS                = 'DECLINE_REMOTE_REQUESTS_SUCCESS';
export const DECLINE_REMOTE_REQUESTS_FAILURE                = 'DECLINE_REMOTE_REQUESTS_FAILURE';
export const GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE_SUCCESS = 'GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE_SUCCESS';
export const GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE_FAILURE = 'GET_REMOTE_INFO_FROM_ASSIGNED_SCHEDULE_FAILURE';
export const SEARCH_SEEKER_TIMESHEETS_SUCCESS               = 'SEARCH_SEEKER_TIMESHEETS_SUCCESS';
export const SEARCH_SEEKER_TIMESHEETS_FAILURE               = 'SEARCH_SEEKER_TIMESHEETS_FAILURE';
export const SEARCH_SEEKER_TIMESHEETS                       = 'SEARCH_SEEKER_TIMESHEETS';
export const UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_SUCCESS    = 'UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_SUCCESS';
export const UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_FAILURE    = 'UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS_FAILURE';
export const UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS            = 'UPDATE_ASSIGNED_SCHEDULE_TIMESHEETS';

// favourite
export const FAVOURITE_EMPLOYEE = 'FAVOURITE_EMPLOYEE';

// block a seeker
export const BLOCK_EMPLOYEE_REQUEST = 'BLOCK_EMPLOYEE_REQUEST';
export const BLOCK_EMPLOYEE_SUCCESS = 'BLOCK_EMPLOYEE_SUCCESS';
export const BLOCK_EMPLOYEE_FAILURE = 'BLOCK_EMPLOYEE_FAILURE';

// unblock a seeker
export const UNBLOCK_EMPLOYEE_REQUEST = 'UNBLOCK_EMPLOYEE_REQUEST';
export const UNBLOCK_EMPLOYEE_SUCCESS = 'UNBLOCK_EMPLOYEE_SUCCESS';
export const UNBLOCK_EMPLOYEE_FAILURE = 'UNBLOCK_EMPLOYEE_FAILURE';

// get blocked seekers
export const GET_BLOCKED_SEEKERS_REQUEST = 'GET_BLOCKED_SEEKERS_REQUEST';
export const GET_BLOCKED_SEEKERS_FAILURE = 'GET_BLOCKED_SEEKERS_FAILURE';
export const GET_BLOCKED_SEEKERS_SUCCESS = 'GET_BLOCKED_SEEKERS_SUCCESS';

// get seeker's ratings
export const GET_RATING_OF_SEEKERS_REQUEST = 'GET_RATING_OF_SEEKERS_REQUEST';
export const GET_RATING_OF_SEEKERS_SUCCESS = 'GET_RATING_OF_SEEKERS_SUCCESS';
export const GET_RATING_OF_SEEKERS_FAILURE = 'GET_RATING_OF_SEEKERS_FAILURE';

// get top seekers
export const GET_TOP_SEEKERS_BY_SHIFTS_REQUEST = 'GET_TOP_SEEKERS_BY_SHIFTS_REQUEST';
export const GET_TOP_SEEKERS_BY_SHIFTS_SUCCESS = 'GET_TOP_SEEKERS_BY_SHIFTS_SUCCESS';
export const GET_TOP_SEEKERS_MONTH_BY_SHIFTS_SUCCESS = 'GET_TOP_SEEKERS_MONTH_BY_SHIFTS_SUCCESS';
export const GET_TOP_SEEKERS_BY_SHIFTS_FAILURE = 'GET_TOP_SEEKERS_BY_SHIFTS_FAILURE';

//get fulfilment report
export const GET_WEEKLY_FULFILMENT_REPORT           = 'GET_WEEKLY_FULFILMENT_REPORT';
export const GET_WEEKLY_FULFILMENT_REPORT_SUCCESS   = 'GET_WEEKLY_FULFILMENT_REPORT_SUCCESS';
export const GET_WEEKLY_FULFILMENT_REPORT_FAILURE   = 'GET_WEEKLY_FULFILMENT_REPORT_FAILURE';

//get billing report
export const GET_BILLING_REPORT             =   'GET_BILLING_REPORT';
export const GET_BILLING_REPORT_SUCCESS     =   'GET_BILLING_REPORT_SUCCESS';
export const GET_BILLING_REPORT_FAILURE     =   'GET_BILLING_REPORT_FAILURE';

// get timecard report
export const GET_WEEKLY_TIMECARD_REPORT          = 'GET_WEEKLY_TIMECARD_REPORT';
export const GET_WEEKLY_TIMECARD_REPORT_SUCCESS  = 'GET_WEEKLY_TIMECARD_REPORT_SUCCESS';
export const GET_WEEKLY_TIMECARD_REPORT_FAILURE  = 'GET_WEEKLY_TIMECARD_REPORT_FAILURE';
export const GET_MONTHLY_TIMECARD_REPORT         = 'GET_MONTHLY_TIMECARD_REPORT';
export const GET_MONTHLY_TIMECARD_REPORT_SUCCESS = 'GET_MONTHLY_TIMECARD_REPORT_SUCCESS';
export const GET_MONTHLY_TIMECARD_REPORT_FAILURE = 'GET_MONTHLY_TIMECARD_REPORT_FAILURE';

// get position statistics
export const GET_POSITIONS_STATISTICS_REQUEST = 'GET_POSITIONS_STATISTICS_REQUEST';
export const GET_POSITIONS_STATISTICS_SUCCESS = 'GET_POSITIONS_STATISTICS_SUCCESS';
export const GET_POSITIONS_STATISTICS_FAILURE = 'GET_POSITIONS_STATISTICS_FAILURE';

// users
export const GET_USERS              = 'GET_USERS';
export const GET_USERS_SUCCESS      = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE      = 'GET_USERS_FAILURE';
export const ADD_USER               = 'ADD_USER';
export const EDIT_USER              = 'EDIT_USER';
export const REMOVE_USER            = 'REMOVE_USER';
export const REMOVE_ALL_USER        = 'REMOVE_ALL_USER';
export const CHECK_USER_EXIST_FAIL  = 'CHECK_USER_EXIST_FAIL';
export const LOADING_USER_PAGE      = 'LOADING_USER_PAGE';
export const DELETE_USER_FAILURE    = 'DELETE_USER_FAILURE';
export const INIT_STATE_USER        = 'INIT_STATE_USER';
export const CREATE_UPDATE_USER_SUCCESS    = 'CREATE_UPDATE_USER_SUCCESS';
export const CONFIRM_USER_SUCCESS_POPUP   = 'CONFIRM_USER_SUCCESS_POPUP';

// get employer list
export const GET_EMPLOYER_LIST         = 'GET_EMPLOYER_LIST';
export const GET_EMPLOYER_LIST_SUCCESS = 'GET_EMPLOYER_LIST_SUCCESS';
export const GET_EMPLOYER_LIST_FAILURE = 'GET_EMPLOYER_LIST_FAILURE';

// get login as
export const LOGIN_AS         = 'LOGIN_AS';
export const LOGIN_AS_SUCCESS = 'LOGIN_AS_SUCCESS';
export const LOGIN_AS_FAILURE = 'LOGIN_AS_FAILURE';

// Message action types
export const OPEN_MESSAGE_MODAL               = 'OPEN_MESSAGE_MODAL';
export const OPEN_DIRECT_MESSAGE_MODAL        = 'OPEN_DIRECT_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL              = 'CLOSE_MESSAGE_MODAL';
export const CHANGE_MESSAGE_TAB               = 'CHANGE_MESSAGE_TAB';
export const GET_MESSAGES                     = 'GET_MESSAGES';
export const GET_READ_MESSAGES                = 'GET_READ_MESSAGES';
export const GET_UNREAD_MESSAGES              = 'GET_UNREAD_MESSAGES';
export const GET_MESSAGES_BY_POSITION         = 'GET_MESSAGES_BY_POSITION';
export const GET_MESSAGES_BY_POSITION_SUCCESS = 'GET_MESSAGES_BY_POSITION_SUCCESS';
export const GET_MESSAGES_BY_POSITION_FAILURE = 'GET_MESSAGES_BY_POSITION_FAILURE';
export const GET_MESSAGES_SUCCESS             = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILURE             = 'GET_MESSAGES_FAILURE';
export const SEND_MESSAGE_TO_POSITION         = 'SEND_MESSAGE_TO_POSITION';
export const SEND_MESSAGE_TO_POSITION_SUCCESS = 'SEND_MESSAGE_TO_POSITION_SUCCESS';
export const SEND_MESSAGE_TO_POSITION_FAILURE = 'SEND_MESSAGE_TO_POSITION_FAILURE';

// Card action types
export const CARD_GET_DETAILS         = 'CARD_GET_DETAILS';
export const CARD_CREATE              = 'CARD_CREATE';
export const CARD_UPDATE              = 'CARD_UPDATE';
export const CARD_DELETE              = 'CARD_DELETE';
export const CARD_CHECK_VALID         = 'CARD_CHECK_VALID';
export const CARD_CHECK_VALID_SUCCESS = 'CARD_CHECK_VALID_SUCCESS';
export const CARD_CHECK_VALID_FAILURE = 'CARD_CHECK_VALID_FAILURE';
export const CREATE_CARD_SUCCESS      = 'CREATE_CARD_SUCCESS';
export const UPDATE_CARD_SUCCESS      = 'UPDATE_CARD_SUCCESS';
export const CREATE_INVALID_CARD      = 'CREATE_INVALID_CARD';
export const GET_CARD_FAILURE         = 'GET_CARD_FAILURE';
export const GET_CARD                 = 'GET_CARD';
export const ADD_CARD_INIT            = 'ADD_CARD_INIT';
export const DONE_CARD_INIT           = 'DONE_CARD_INIT';
export const FIRST_TIME_GET_CARD      = 'FIRST_TIME_GET_CARD';

// Timecard action types
export const TIMECARD_CREATE         = 'TIMECARD_CREATE';
export const TIMECARD_CREATE_SUCCESS = 'TIMECARD_CREATE_SUCCESS';
export const TIMECARD_CREATE_FAILURE = 'TIMECARD_CREATE_FAILURE';

// Search seekers action types
export const SEEKER_SEARCH                       = 'SEEKER_SEARCH';
export const SEEKER_SEARCH_SUCCESS               = 'SEEKER_SEARCH_SUCCESS';
export const SEEKER_SEARCH_FOR_ADD_SUCCESS       = 'SEEKER_SEARCH_FOR_ADD_SUCCESS';
export const SEEKER_SEARCH_FAILURE               = 'SEEKER_SEARCH_FAILURE';
export const SEEKER_SEARCH_RESET_RESULTS         = 'SEEKER_SEARCH_RESET_RESULTS';

// Nudge seekers action types
export const NUDGE_SEEKER_SHOW_MODAL                = 'NUDGE_SEEKER_SHOW_MODAL';
export const NUDGE_SEEKER_HIDE_MODAL                = 'NUDGE_SEEKER_HIDE_MODAL';
export const NUDGE_SEEKER_GET_NUDGABLE_LIST         = 'NUDGE_SEEKER_GET_NUDGABLE_LIST';
export const NUDGE_SEEKER_GET_NUDGABLE_LIST_SUCCESS = 'NUDGE_SEEKER_GET_NUDGABLE_LIST_SUCCESS';
export const NUDGE_SEEKER_GET_NUDGABLE_LIST_FAILURE = 'NUDGE_SEEKER_GET_NUDGABLE_LIST_FAILURE';
export const NUDGE_SEEKER_FOR_POSITION              = 'NUDGE_SEEKER_FOR_POSITION';
export const NUDGE_SEEKER_FOR_POSITION_SUCCESS      = 'NUDGE_SEEKER_FOR_POSITION_SUCCESS';
export const NUDGE_SEEKER_FOR_POSITION_FAILURE      = 'NUDGE_SEEKER_FOR_POSITION_FAILURE';
export const NUDGE_SEEKER_FOR_POSITION_UNAVAILABLE  = 'NUDGE_SEEKER_FOR_POSITION_UNAVAILABLE';
export const NUDGE_SEEKER_HIDE_FAILURE_POPUP        = 'NUDGE_SEEKER_HIDE_FAILURE_POPUP';

export const NUDGE_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS = 'NUDGE_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS';

// seeker signal
export const GET_SEEKER_SIGNAL = 'GET_SEEKER_SIGNAL';
export const GET_SEEKER_SIGNAL_SUCCESS = 'GET_SEEKER_SIGNAL_SUCCESS';
export const GET_SEEKER_SIGNAL_FAIL = 'GET_SEEKER_SIGNAL_FAIL';
export const CLEAR_LIST_SEEKER_SIGNAL = 'CLEAR_LIST_SEEKER_SIGNAL';


// add seekers action types
export const ADD_SEEKER_SHOW_MODAL                = 'ADD_SEEKER_SHOW_MODAL';
export const ADD_SEEKER_HIDE_MODAL                = 'ADD_SEEKER_HIDE_MODAL';
export const SEARCH_SEEKER                        = 'SEARCH_SEEKER';
export const SEARCH_SEEKER_SUCCESS                = 'SEARCH_SEEKER_SUCCESS';
export const SEARCH_SEEKER_FAILURE                = 'SEARCH_SEEKER_FAILURE';
export const ADD_SEEKER_FOR_POSITION              = 'ADD_SEEKER_FOR_POSITION';
export const ADD_SEEKER_FOR_POSITION_SUCCESS      = 'ADD_SEEKER_FOR_POSITION_SUCCESS';
export const ADD_SEEKER_FOR_POSITION_FAILURE      = 'ADD_SEEKER_FOR_POSITION_FAILURE';
export const ADD_SEEKER_FOR_POSITION_EXCEEDED     = 'ADD_SEEKER_FOR_POSITION_EXCEEDED';
export const ADD_SEEKER_FOR_POSITION_UNAVAILABLE  = 'ADD_SEEKER_FOR_POSITION_UNAVAILABLE';
export const ADD_SEEKER_HIDE_FAILURE_POPUP        = 'ADD_SEEKER_HIDE_FAILURE_POPUP';
export const ADD_SEEKER_OVER_18                   = 'ADD_SEEKER_OVER_18';

export const ADD_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS = 'ADD_SEEKER_FOR_POSITION_RESTRICTION_48_HOURS';

// Invoice action type
export const GET_INVOICES                                   = 'GET_INVOICES';
export const GET_ALL_INVOICES                               = 'GET_ALL_INVOICES';
export const GET_INVOICES_SUCCESS                           = 'GET_INVOICES_SUCCESS';
export const EXPORT_PDF                                     = 'EXPORT_PDF';
export const GET_INVOICE_PDF_LINK_SUCCESS                   = 'GET_INVOICE_PDF_LINK_SUCCESS';
export const SEND_INVOICE_EMAILL_SUCCESS                    = 'SEND_INVOICE_EMAILL_SUCCESS';
export const RESET_INVOICE_POPUP                            = 'RESET_INVOICE_POPUP';
export const SEND_INVOICE_MAIL                              = 'SEND_INVOICE_MAIL';
export const UNMOUNT_INVOICE                                = 'UNMOUNT_INVOICE';
export const DONE_LOADING_INVOICE                           = 'DONE_LOADING_INVOICE';
export const INIT_LOADING                                   = 'INIT_LOADING';

// Notification action types
export const NOTIFICATION_SHOW_PANEL                  = 'NOTIFICATION_SHOW_PANEL';
export const NOTIFICATION_HIDE_PANEL                  = 'NOTIFICATION_HIDE_PANEL';
export const NOTIFICATION_LOAD_NOTIFICATIONS          = 'NOTIFICATION_LOAD_NOTIFICATIONS';
export const NOTIFICATION_LOAD_NOTIFICATIONS_SUCCESS  = 'NOTIFICATION_LOAD_NOTIFICATIONS_SUCCESS';
export const NOTIFICATION_LOAD_NOTIFICATIONS_FAILURE  = 'NOTIFICATION_LOAD_NOTIFICATIONS_FAILURE';
export const NOTIFICATION_MARK_AS_READ                = 'NOTIFICATION_MARK_AS_READ';
export const NOTIFICATION_MARK_AS_READ_SUCCESS        = 'NOTIFICATION_MARK_AS_READ_SUCCESS';
export const NOTIFICATION_MARK_AS_READ_FAILURE        = 'NOTIFICATION_MARK_AS_READ_FAILURE';
export const NOTIFICATION_REMOVE_NOTIFICATION         = 'NOTIFICATION_REMOVE_NOTIFICATION';
export const NOTIFICATION_REMOVE_NOTIFICATION_SUCCESS = 'NOTIFICATION_REMOVE_NOTIFICATION_SUCCESS';
export const NOTIFICATION_REMOVE_NOTIFICATION_FAILURE = 'NOTIFICATION_REMOVE_NOTIFICATION_FAILURE';
export const NOTIFICATION_GET_NUMBER_UNREAD           = 'NOTIFICATION_GET_NUMBER_UNREAD';
export const NOTIFICATION_GET_NUMBER_UNREAD_SUCCESS   = 'NOTIFICATION_GET_NUMBER_UNREAD_SUCCESS';
export const NOTIFICATION_GET_NUMBER_UNREAD_FAILURE   = 'NOTIFICATION_GET_NUMBER_UNREAD_FAILURE';
export const NOTIFICATION_LOAD_JOB_DETAILS            = 'NOTIFICATION_LOAD_JOB_DETAILS';
export const NOTIFICATION_LOAD_JOB_DETAILS_SUCCESS    = 'NOTIFICATION_LOAD_JOB_DETAILS_SUCCESS';
export const NOTIFICATION_LOAD_JOB_DETAILS_FAILURE    = 'NOTIFICATION_LOAD_JOB_DETAILS_FAILURE';
export const NOTIFICATION_RESET_JOB_DETAILS           = 'NOTIFICATION_RESET_JOB_DETAILS'

// Confirm password action types
export const CONFIRM_PASSWORD_SHOW_FORM               = 'CONFIRM_PASSWORD_SHOW_FORM';
export const CONFIRM_PASSWORD_HIDE_FORM               = 'CONFIRM_PASSWORD_HIDE_FORM';
export const CONFIRM_PASSWORD_VERIFY_PASSWORD         = 'CONFIRM_PASSWORD_VERIFY_PASSWORD';
export const CONFIRM_PASSWORD_VERIFY_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_VERIFY_PASSWORD_SUCCESS';
export const CONFIRM_PASSWORD_VERIFY_PASSWORD_FAILURE = 'CONFIRM_PASSWORD_VERIFY_PASSWORD_FAILURE';
export const CONFIRM_PASSWORD_WRONG_PASSWORD          = 'CONFIRM_PASSWORD_WRONG_PASSWORD';

//Amending Hours
export const AMENDING_HOURS_SHOW_FORM           = 'AMENDING_HOURS_SHOW_FORM';
export const AMENDING_HOURS_HIDE_FORM           = 'AMENDING_HOURS_HIDE_FORM';
export const AMENDING_HOURS_ADD_COMMENT         = 'AMENDING_HOURS_ADD_COMMENT';
export const AMENDING_HOURS_ADD_COMMENT_SUCCESS = 'AMENDING_HOURS_ADD_COMMENT_SUCCESS';
export const AMENDING_HOURS_ADD_COMMENT_FAILURE = 'AMENDING_HOURS_ADD_COMMENT_FAILURE';
// Split shifts
export const SPLIT_SHIFTS_SHOW_CONFIRMATION_POPUP = 'SPLIT_SHIFTS_SHOW_CONFIRMATION_POPUP';
export const SPLIT_SHIFTS_HIDE_CONFIRMATION_POPUP = 'SPLIT_SHIFTS_HIDE_CONFIRMATION_POPUP';
export const SPLIT_SHIFTS_DO_SPLIT                = 'SPLIT_SHIFTS_DO_SPLIT';
export const SPLIT_SHIFTS_DO_SPLIT_SUCCESS        = 'SPLIT_SHIFTS_DO_SPLIT_SUCCESS';
export const SPLIT_SHIFTS_DO_SPLIT_FAILURE        = 'SPLIT_SHIFTS_DO_SPLIT_FAILURE';

// Charge Rate Action Type
export const GET_CHARGERATES         = 'GET_CHARGERATES';
export const GET_CHARGERATES_SUCCESS = 'GET_CHARGERATES_SUCCESS';
export const GET_CHARGERATES_FAILURE = 'GET_CHARGERATES_FAILURE';

export const POST_CHARGERATES         = 'POST_CHARGERATES';
export const POST_CHARGERATES_SUCCESS = 'POST_CHARGERATES_SUCCESS';
export const POST_CHARGERATES_FAILURE = 'POST_CHARGERATES_FAILURE';
//Qr code

export const OPEN_POPUP_QR_CODE                   = 'OPEN_POPUP_QR_CODE';
export const CLOSE_POPUP_QR_CODE                  = 'CLOSE_POPUP_QR_CODE';
export const GET_QR_CODE_EMPLOYER                 = 'GET_QR_CODE_EMPLOYER';
export const GET_QR_CODE_EMPLOYER_SUCCESS         = 'GET_QR_CODE_EMPLOYER_SUCCESS';
export const REFRESH_QR_CODE_EMPLOYER             = 'REFRESH_QR_CODE_EMPLOYER';
export const REFRESH_QR_CODE_EMPLOYER_SUCCESS     = 'REFRESH_QR_CODE_EMPLOYER_SUCCESS';


export const OPEN_LOGIN_DETAIL_SETTING_POPUP        = 'OPEN_LOGIN_DETAIL_SETTING_POPUP';
export const CLOSE_LOGIN_DETAIL_SETTING_POPUP       = 'CLOSE_LOGIN_DETAIL_SETTING_POPUP';
export const GET_LOGIN_DETAIL_SETTING               = 'GET_LOGIN_DETAIL_SETTING';
export const GET_LOGIN_DETAIL_SETTING_SUCCESS       = 'GET_LOGIN_DETAIL_SETTING_SUCCESS';
export const UPDATE_OBJECT_LOGIN_DETAIL_SETTING     = 'UPDATE_OBJECT_LOGIN_DETAIL_SETTING';
export const UPDATE_LOGIN_DETAIL_SETTING            = 'UPDATE_LOGIN_DETAIL_SETTING';
export const UPDATE_LOGIN_DETAIL_SETTING_SUCCESS    = 'UPDATE_LOGIN_DETAIL_SETTING_SUCCESS';
export const UPDATE_LOGIN_DETAIL_SETTING_FAIL       = 'UPDATE_LOGIN_DETAIL_SETTING_FAIL';
export const CONFIRM_ERROR_SETTING_POPUP            = 'CONFIRM_ERROR_SETTING_POPUP';
export const UPDATE_LOGIN_DETAIL_SEND_MAIL_SUCCESS  = 'UPDATE_LOGIN_DETAIL_SEND_MAIL_SUCCESS';
export const CONFIRM_SUCCESS_SETTING_POPUP          = 'CONFIRM_SUCCESS_SETTING_POPUP';
export const LOAD_INIT_DATA_LOGIN_DETAIL_DONE       = 'LOAD_INIT_DATA_LOGIN_DETAIL_DONE';


export const GET_MASTER_RATE         = 'GET_MASTER_RATE';
export const GET_MASTER_RATE_SUCCESS = 'GET_MASTER_RATE_SUCCESS';
export const GET_MASTER_RATE_FAILURE = 'GET_MASTER_RATE_FAILURE';

export const POST_MASTER_RATE         = 'POST_MASTER_RATE';
export const POST_MASTER_RATE_SUCCESS = 'POST_MASTER_RATE_SUCCESS';
export const POST_MASTER_RATE_FAILURE = 'POST_MASTER_RATE_FAILURE';

export const GET_BUSINESS_DETAIL_SETTING                      = 'GET_BUSINESS_DETAIL_SETTING';
export const GET_BUSINESS_DETAIL_SETTING_SUCCESS              = 'GET_BUSINESS_DETAIL_SETTING_SUCCESS';
export const CLOSE_BUSINESS_DETAIL_SETTING_POPUP              = 'CLOSE_BUSINESS_DETAIL_SETTING_POPUP';
export const OPEN_BUSINESS_DETAIL_SETTING_POPUP               = 'OPEN_BUSINESS_DETAIL_SETTING_POPUP';
export const UPDATE_OBJECT_BUSINESS_DETAIL_SETTING            = 'UPDATE_OBJECT_BUSINESS_DETAIL_SETTING';
export const UPDATE_BUSINESS_DETAIL_SETTING                   = 'UPDATE_BUSINESS_DETAIL_SETTING';
export const UPDATE_BUSINESS_DETAIL_SETTING_SUCCESS           = 'UPDATE_BUSINESS_DETAIL_SETTING_SUCCESS';
export const UPDATE_BUSINESS_DETAIL_SETTING_FAIL              = 'UPDATE_BUSINESS_DETAIL_SETTING_FAIL';
export const CONFIRM_BUSINESS_DETAIL_ERROR_SETTING_POPUP      = 'CONFIRM_BUSINESS_DETAIL_ERROR_SETTING_POPUP';
export const CONFIRM_BUSINESS_DETAIL_SUCCESS_SETTING_POPUP    = 'CONFIRM_BUSINESS_DETAIL_SUCCESS_SETTING_POPUP';

export const PDF_OPEN_VIEWER                    = 'PDF_OPEN_VIEWER';
export const PDF_CLOSE_VIEWER                   = 'PDF_CLOSE_VIEWER';
export const PDF_GET_TIMESHEET_PENDING          = 'PDF_GET_TIMESHEET_PENDING';
export const PDF_GET_TIMESHEET_PENDING_SUCCESS  = 'PDF_GET_TIMESHEET_PENDING_SUCCESS';
export const PDF_GET_TIMESHEET_PENDING_FAILURE  = 'PDF_GET_TIMESHEET_PENDING_FAILURE';
export const PDF_GET_TIMESHEET_APPROVED         = 'PDF_GET_TIMESHEET_APPROVED';
export const PDF_GET_TIMESHEET_APPROVED_SUCCESS = 'PDF_GET_TIMESHEET_APPROVED_SUCCESS';
export const PDF_GET_TIMESHEET_APPROVED_FAILURE = 'PDF_GET_TIMESHEET_APPROVED_FAILURE';
export const PDF_GET_APPLIED                    = 'PDF_GET_APPLIED';
export const PDF_GET_APPLIED_SUCCESS            = 'PDF_GET_APPLIED_SUCCESS';
export const PDF_GET_APPLIED_FAILURE            = 'PDF_GET_APPLIED_FAILURE';
export const PDF_GET_CONFIRMED                  = 'PDF_GET_CONFIRMED';
export const PDF_GET_CONFIRMED_SUCCESS          = 'PDF_GET_CONFIRMED_SUCCESS';
export const PDF_GET_CONFIRMED_FAILURE          = 'PDF_GET_CONFIRMED_FAILURE';
export const PDF_GET_WEEKLY_TIMESHEET           = 'PDF_GET_WEEKLY_TIMESHEET';
export const PDF_GET_WEEKLY_TIMESHEET_SUCCESS   = 'PDF_GET_WEEKLY_TIMESHEET_SUCCESS';
export const PDF_GET_WEEKLY_TIMESHEET_FAILURE   = 'PDF_GET_WEEKLY_TIMESHEET_FAILURE';
export const PDF_GET_DAILY_UPCOMING_JOB         = 'PDF_GET_DAILY_UPCOMING_JOB';
export const PDF_GET_DAILY_UPCOMING_JOB_SUCCESS = 'PDF_GET_DAILY_UPCOMING_JOB_SUCCESS';
export const PDF_GET_DAILY_UPCOMING_JOB_FAILURE = 'PDF_GET_DAILY_UPCOMING_JOB_FAILURE';
export const PDF_GET_DAILY_PENDING_JOB          = 'PDF_GET_DAILY_PENDING_JOB';
export const PDF_GET_DAILY_PENDING_JOB_SUCCESS  = 'PDF_GET_DAILY_PENDING_JOB_SUCCESS';
export const PDF_GET_DAILY_PENDING_JOB_FAILURE  = 'PDF_GET_DAILY_PENDING_JOB_FAILURE';
export const GET_INTERNAL_EMPLOYEE_LIST         = 'GET_INTERNAL_EMPLOYEE_LIST';
export const GET_INTERNAL_EMPLOYEE_LIST_SUCCESS = 'GET_INTERNAL_EMPLOYEE_LIST_SUCCESS';
export const GET_INTERNAL_EMPLOYEE_LIST_FAILURE = 'GET_INTERNAL_EMPLOYEE_LIST_FAILURE';
export const ADD_INTERNAL_EMPLOYEE              = 'ADD_INTERNAL_EMPLOYEE';
export const ADD_INTERNAL_EMPLOYEE_SUCCESS      = 'ADD_INTERNAL_EMPLOYEE_SUCCESS';
export const ADD_INTERNAL_EMPLOYEE_FAILURE      = 'ADD_INTERNAL_EMPLOYEE_FAILURE';
export const UPDATE_INTERNAL_EMPLOYEE           = 'UPDATE_INTERNAL_EMPLOYEE';
export const UPDATE_INTERNAL_EMPLOYEE_SUCCESS   = 'UPDATE_INTERNAL_EMPLOYEE_SUCCESS';
export const UPDATE_INTERNAL_EMPLOYEE_FAILURE   = 'UPDATE_INTERNAL_EMPLOYEE_FAILURE';
export const DELETE_INTERNAL_EMPLOYEE           = 'DELETE_INTERNAL_EMPLOYEE';
export const DELETE_INTERNAL_EMPLOYEE_SUCCESS   = 'DELETE_INTERNAL_EMPLOYEE_SUCCESS';
export const DELETE_INTERNAL_EMPLOYEE_FAILURE   = 'DELETE_INTERNAL_EMPLOYEE_FAILURE';
export const CSV_IMPORT_INTERNAL_EMPLOYEES         = 'CSV_IMPORT_INTERNAL_EMPLOYEES';
export const CSV_IMPORT_INTERNAL_EMPLOYEES_SUCCESS = 'CSV_IMPORT_INTERNAL_EMPLOYEES_SUCCESS';
export const CSV_IMPORT_INTERNAL_EMPLOYEES_FAILURE = 'CSV_IMPORT_INTERNAL_EMPLOYEES_FAILURE';

// All action types for Modal All Jobs
export const MODAL_ALL_JOBS_OPEN  = 'MODAL_ALL_JOBS_OPEN';
export const MODAL_ALL_JOBS_CLOSE = 'MODAL_ALL_JOBS_CLOSE';

// All action types for Vacancy
export const GET_VACANCY_LOCATIONS         = 'GET_VACANCY_LOCATIONS';
export const GET_VACANCY_LOCATIONS_SUCCESS = 'GET_VACANCY_LOCATIONS_SUCCESS';
export const GET_VACANCY_LOCATIONS_FAILURE = 'GET_VACANCY_LOCATIONS_FAILURE';

// All action types for export/download Excel
export const EXCEL_DOWNLOAD_TIMESHEET_PENDING          = 'EXCEL_DOWNLOAD_TIMESHEET_PENDING';
export const EXCEL_DOWNLOAD_TIMESHEET_PENDING_SUCCESS  = 'EXCEL_DOWNLOAD_TIMESHEET_PENDING_SUCCESS';
export const EXCEL_DOWNLOAD_TIMESHEET_APPROVED         = 'EXCEL_DOWNLOAD_TIMESHEET_APPROVED';
export const EXCEL_DOWNLOAD_TIMESHEET_APPROVED_SUCCESS = 'EXCEL_DOWNLOAD_TIMESHEET_APPROVED_SUCCESS';
export const EXCEL_DOWNLOAD_TIMESHEET_WEEKLY           = 'EXCEL_DOWNLOAD_TIMESHEET_WEEKLY';
export const EXCEL_DOWNLOAD_TIMESHEET_WEEKLY_SUCCESS   = 'EXCEL_DOWNLOAD_TIMESHEET_WEEKLY_SUCCESS';
export const EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY         = 'EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY';
export const EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY_SUCCESS = 'EXCEL_DOWNLOAD_UPCOMING_JOB_DAILY_SUCCESS';
export const EXCEL_DOWNLOAD_PENDING_JOB_DAILY          = 'EXCEL_DOWNLOAD_PENDING_JOB_DAILY';
export const EXCEL_DOWNLOAD_PENDING_JOB_DAILY_SUCCESS  = 'EXCEL_DOWNLOAD_PENDING_JOB_DAILY_SUCCESS';
export const EXCEL_DOWNLOAD_APPLIED                    = 'EXCEL_DOWNLOAD_APPLIED';
export const EXCEL_DOWNLOAD_APPLIED_SUCCESS            = 'EXCEL_DOWNLOAD_APPLIED_SUCCESS';
export const EXCEL_DOWNLOAD_CONFIRMED                  = 'EXCEL_DOWNLOAD_CONFIRMED';
export const EXCEL_DOWNLOAD_CONFIRMED_SUCCESS          = 'EXCEL_DOWNLOAD_CONFIRMED_SUCCESS';
export const SEND_MAIL_TIMESHEET_PENDING               = 'SEND_MAIL_TIMESHEET_PENDING';
export const SEND_MAIL_TIMESHEET_PENDING_SUCCESS       = 'SEND_MAIL_TIMESHEET_PENDING_SUCCESS';

// All action types for Direct message
export const DIRECT_MESSAGE_OPEN_MODAL = 'DIRECT_MESSAGE_OPEN_MODAL';
export const DIRECT_MESSAGE_CLOSE_MODAL = 'DIRECT_MESSAGE_CLOSE_MODAL';
export const DIRECT_MESSAGE_OPEN_SEARCH = 'DIRECT_MESSAGE_OPEN_SEARCH';
export const DIRECT_MESSAGE_CLOSE_SEARCH = 'DIRECT_MESSAGE_CLOSE_SEARCH';
export const DIRECT_MESSAGE_CREATE_CONVERSATION = 'DIRECT_MESSAGE_CREATE_CONVERSATION';
export const DIRECT_MESSAGE_CREATE_CONVERSATION_SUCCESS = 'DIRECT_MESSAGE_CREATE_CONVERSATION_SUCCESS';
export const DIRECT_MESSAGE_CREATE_CONVERSATION_FAILURE = 'DIRECT_MESSAGE_CREATE_CONVERSATION_FAILURE';

export const GET_CONVERSATION = 'GET_CONVERSATION';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';

export const GET_DIRECT_MESSAGE = 'GET_DIRECT_MESSAGE';
export const GET_DIRECT_MESSAGE_SUCCESS = 'GET_DIRECT_MESSAGE_SUCCESS';
export const GET_DIRECT_MESSAGE_FAILURE = 'GET_DIRECT_MESSAGE_FAILURE';
export const CREATE_DIRECT_MESSAGE = 'CREATE_DIRECT_MESSAGE';
export const CREATE_DIRECT_MESSAGE_SUCCESS = 'CREATE_DIRECT_MESSAGE_SUCCESS';

export const CLOSE_CONVERSAION = 'CLOSE_CONVERSATION';
export const CLOSE_CONVERSAION_SUCCESS = 'CLOSE_CONVERSATION_SUCCESS';

export const DELETE_CONVERSAION = 'DELETE_CONVERSAION';
export const DELETE_CONVERSAION_SUCCESS = 'DELETE_CONVERSAION_SUCCESS';
export const DELETE_CONVERSAION_FAILURE = 'DELETE_CONVERSAION_FAILURE';
export const OPEN_CONVERSATION = 'OPEN_CONVERSATION';
export const OPEN_CONVERSATION_SUCCESS = 'OPEN_CONVERSATION_SUCCESS';

export const CREATE_NEW_EMPLOYER = 'CREATE_NEW_EMPLOYER';
export const UPDATE_EMPLOYER = 'UPDATE_EMPLOYER';
export const CREATE_NEW_EMPLOYER_SUCCESS = 'CREATE_NEW_EMPLOYER_SUCCESS';
export const CREATE_NEW_EMPLOYER_FAILURE = 'CREATE_NEW_EMPLOYER_FAILURE';
export const UPDATE_EMPLOYER_FAILURE = 'UPDATE_EMPLOYER_FAILURE';
export const UPDATE_EMPLOYER_SUCCESS = 'UPDATE_EMPLOYER_SUCCESS';
export const UPDATE_LIST_PROVIDER_SUCCESS = 'UPDATE_LIST_PROVIDER_SUCCESS';
export const CLOSE_POPUP= 'CLOSE_POPUP';

// Provider
export const GET_LIST_PROVIDER_REQUEST = 'GET_LIST_PROVIDER_REQUEST';
export const GET_LIST_PROVIDER_SUCCESS = 'GET_LIST_PROVIDER_SUCCESS';
export const GET_LIST_PROVIDER_FAILURE = 'GET_LIST_PROVIDER_FAILURE';

// Seeker
export const GET_LIST_SEEKER_REQUEST = 'GET_LIST_SEEKER_REQUEST';
export const GET_LIST_SEEKER_SUCCESS = 'GET_LIST_SEEKER_SUCCESS';
export const GET_LIST_SEEKER_FAILURE = 'GET_LIST_SEEKER_FAILURE';
export const GET_EMPLOYEE_DETAIL     = 'GET_EMPLOYEE_DETAIL';
export const GET_EMPLOYEE_DETAIL_SUCCESS     = 'GET_EMPLOYEE_DETAIL_SUCCESS';
export const GET_EMPLOYEE_DETAIL_FAILURE     = 'GET_EMPLOYEE_DETAIL_FAILURE';
export const UPDATE_EMPLOYEE_DETAIL     = 'UPDATE_EMPLOYEE_DETAIL';
export const UPDATE_EMPLOYEE_DETAIL_SUCCESS    = 'UPDATE_EMPLOYEE_DETAIL_SUCCESS';
export const HANDLE_BLOCK_SEEKER                = 'HANDLE_BLOCK_SEEKER';
export const BLOCK_UNLOCK_SUCCESS               = 'BLOCK_UNLOCK_SUCCESS';
export const BLOCK_UNLOCK_FAILURE               = 'BLOCK_UNLOCK_FAILURE';
export const HANDLE_UNBLOCK_SEEKER                = 'HANDLE_UNBLOCK_SEEKER';
export const UPDATE_EMPLOYEE_DETAIL_FAILURE    = 'UPDATE_EMPLOYEE_DETAIL_FAILURE';
export const RESET_LOADING_DETAIL = 'RESET_LOADING_DETAIL'
export const RESET_UPDATE_POPUP = 'RESET_UPDATE_POPUP'

export const GET_EMPLOYEE_OF_JOB = 'GET_EMPLOYEE_OF_JOB';
export const GET_EMPLOYEE_OF_JOB_SUCCESS = 'GET_EMPLOYEE_OF_JOB_SUCCESS';
export const GET_EMPLOYEE_OF_JOB_FAILURE = 'GET_EMPLOYEE_OF_JOB_FAILURE';

export const GET_DOCUMENT_OF_EMPLOYEE = 'GET_DOCUMENT_OF_EMPLOYEE';
export const CHANGE_DOCUMENT_LOADING = 'CHANGE_DOCUMENT_LOADING'
export const GET_DOCUMENT_OF_EMPLOYEE_SUCCESS = 'GET_DOCUMENT_OF_EMPLOYEE_SUCCESS';
export const GET_DOCUMENT_OF_EMPLOYEE_FAILURE = 'GET_DOCUMENT_OF_EMPLOYEE_FAILURE';
export const DELETE_SEEKER_BLOCK = 'DELETE_SEEKER_BLOCK';
export const DELETE_EMPLOYEE_EXPERIENCE = 'DELETE_EMPLOYEE_EXPERIENCE';
export const DELETE_EMPLOYEE_EXPERIENCE_SUCCESS = 'DELETE_EMPLOYEE_EXPERIENCE_SUCCESS';
export const DELETE_EMPLOYEE_EXPERIENCE_FAILURE = 'DELETE_EMPLOYEE_EXPERIENCE_FAILURE';
export const ADD_HISTORY = 'ADD_HISTORY';
export const ADD_HISTORY_SUCCESS = 'ADD_HISTORY_SUCCESS';
export const ADD_HISTORY_FAILURE = 'ADD_HISTORY_FAILURE';
export const DELETE_HISTORY = 'DELETE_HISTORY';
export const OPEN_SEEKER_DETAIL = 'OPEN_SEEKER_DETAIL';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

export const EXCEL_GET_WEEKLY_TIMESHEET_FALIURE = 'EXCEL_GET_WEEKLY_TIMESHEET_FALIURE';
export const EXCEL_GET_WEEKLY_TIMESHEET_SUCCESS = 'EXCEL_GET_WEEKLY_TIMESHEET_SUCCESS';
export const EXCEL_GET_WEEKLY_TIMESHEET = 'EXCEL_GET_WEEKLY_TIMESHEET';

export const GET_JOB_TYPE = 'GET_JOB_TYPE';
export const GET_JOB_TYPE_SUCCESS = 'GET_JOB_TYPE_SUCCESS';

export const GET_EMPLOYEE_LIST_JOB_APPLY = 'GET_EMPLOYEE_LIST_JOB_APPLY';
export const GET_EMPLOYEE_LIST_JOB_APPLY_SUCCESS = 'GET_EMPLOYEE_LIST_JOB_APPLY_SUCCESS';
export const GET_EMPLOYEE_LIST_JOB_APPLY_FAILURE= 'GET_EMPLOYEE_LIST_JOB_APPLY_FAILURE';


export const ADD_NOTE='ADD_NOTE'
export const ADD_NOTE_SUCCESS='ADD_NOTE_SUCCESS'
export const ADD_NOTE_FAILURE='ADD_NOTE_FAILURE'

export const GET_INIT_JOB = 'GET_INIT_JOB';

export const GET_SKILL_BY_SKILL_TYPE = 'GET_SKILL_BY_SKILL_TYPE'
export const GET_SKILL_BY_SKILL_TYPE_SUCCESS = 'GET_SKILL_BY_SKILL_TYPE_SUCCESS';
export const GET_SKILL_BY_SKILL_TYPE_FAILURE = 'GET_SKILL_BY_SKILL_TYPE_FAILURE';

export const EXCEL_DOWNLOAD_WEEKLY_HOUR = 'EXCEL_DOWNLOAD_WEEKLY_HOUR';
export const SWITCH_JOB_STATUS = 'SWITCH_JOB_STATUS';
export const SWITCH_JOB_STATUS_SUCCESS = 'SWITCH_JOB_STATUS_SUCCESS';


export const OPEN_ALERT_DELETE_CONVERSATION_SUCCESS = 'OPEN_ALERT_DELETE_CONVERSATION_SUCCESS';
export const CLOSE_ALERT_DELETE_CONVERSATION_SUCCESS = 'CLOSE_ALERT_DELETE_CONVERSATION_SUCCESS';

export const CHECK_WORKING_OVER_SIX_DAY = 'CHECK_WORKING_OVER_SIX_DAY';

export const CHECK_WORKING_OVER_SIX_DAY_SUCCESS = 'CHECK_WORKING_OVER_SIX_DAY_SUCCESS';

export const CHECK_WORKING_OVER_SIX_DAY_FAILURE = 'CHECK_WORKING_OVER_SIX_DAY_FAILURE';

export const WARNING_SEEKER_OVER_SIX_DAY = 'WARNING_SEEKER_OVER_SIX_DAY';

export const HIDE_WARNING_SEEKER_OVER_SIX_DAY = 'HIDE_WARNING_SEEKER_OVER_SIX_DAY';

export const CHECK_SEEKER_NOT_TO_JOB = 'CHECK_SEEKER_NOT_TO_JOB';
export const CHECK_SEEKER_NOT_TO_JOB_SUCCESS = 'CHECK_SEEKER_NOT_TO_JOB_SUCCESS';
export const CHECK_SEEKER_NOT_TO_JOB_FAILURE = 'CHECK_SEEKER_NOT_TO_JOB_FAILURE';

export const WARNING_SEEKER_48_HOURS = 'WARNING_SEEKER_48_HOURS';
export const HIDE_WARNING_SEEKER_48_HOURS = 'HIDE_WARNING_SEEKER_48_HOURS';

export const WARNING_SEEKER_IS_STUDENT = 'WARNING_SEEKER_IS_STUDENT';
export const HIDE_WARNING_SEEKER_IS_STUDENT = 'HIDE_WARNING_SEEKER_IS_STUDENT';

export const WARNING_SEEKER_IN_8_HOURS = 'WARNING_SEEKER_IN_8_HOURS';
export const HIDE_WARNING_SEEKER_IN_8_HOURS = 'HIDE_WARNING_SEEKER_IN_8_HOURS';

export const WARNING_SEEKER_IS_OVERLAPS = 'WARNING_SEEKER_IS_OVERLAPS';
export const HIDE_WARNING_SEEKER_IS_OVERLAPS = 'HIDE_WARNING_SEEKER_IS_OVERLAPS';

export const CHECK_SEEKER_ACCEPT_TO_JOB = 'CHECK_SEEKER_ACCEPT_TO_JOB';
export const CHECK_SEEKER_ACCEPT_TO_JOB_SUCCEESS = 'CHECK_SEEKER_ACCEPT_TO_JOB_SUCCESS';
export const CHECK_SEEKER_ACCEPT_TO_JOB_FAILURE = 'CHECK_SEEKER_ACCEPT_TO_JOB_FAILURE';

export const WARNING_ACCEPT_SEEKER_TO_JOB = 'WARNING_ACCEPT_SEEKER_TO_JOB'
export const HIDE_WARNING_ACCEPT_SEEKER_TO_JOB = 'HIDE_WARNING_ACCEPT_SEEKER_TO_JOB'
export const CLOSE_ALL_POPUP_ASSIGNMENT = 'CLOSE_ALL_POPUP_ASSIGNMENT'
export const CLOSE_ALL_JOB_MODAL = "CLOSE_ALL_JOB_MODAL";