import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input
} from 'reactstrap';
import Warning from 'Assets/img/SweetAlert/warning.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from "@material-ui/core/FormHelperText";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Modal from '@material-ui/core/Modal';
import IntlMessages from 'Util/IntlMessages';
import {
    hideAddSeekerModal,
    addSeekerForPosition,
    hideFailurePopup,
    searchSeekers,
    searchSeekersResetResults,
    checkWorkingSixDay,
    hideWarningWorkingSixDay,
    checkSeekerNotToJob,
    hideWarningSeeker48h,
    hideWarningSeekerIsStudent,
    hideWarningSeekerIn8hours
} from 'Actions';

import { currentPage } from 'Helpers/RouterChecker';
import MyGig from 'Constants/MyGig';
import KeyCodes from 'Constants/KeyCodes';

import ListSeekers from 'Components/ListSeekers/ListSeekers';
import Spinner from 'Components/Model/Spinner';

const SEARCH_SEEKER_INPUT_MIN_LENGTH = 3;

class AddSeeker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addedListIds: [],
            filterForm: {
                searchText: '',
            },
            validation: {
                searchText: null
            },
            paginator: {
                page: 1
            },
            sweetAlerts: {
                confirmAdd: false
            },
            confirmAddSeekerId: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.addSuccess === true && nextProps.addSuccess === false) {
            const { addedListIds } = this.state;
            addedListIds.push(this.props.lastAddedId);
            this.setState({ addedListIds });
        }
    }

    setCurrentPaginatorPage(page) {
        if (this.state.paginator.page !== page) {
            this.setState({
                paginator: { page }
            });
        }
    }

    getCurrentPaginatorPage() {
        return this.state.paginator.page;
    }

    handleCloseModal() {
        this.setState({
            addedListIds: [],
            filterForm: {
                searchText: ''
            },
            validation: {
                searchText: null
            },
            paginator: {
                page: 1
            }
        });
        this.props.hideAddSeekerModal();
        this.props.searchSeekersResetResults();
    }

    handleKeyupSearchInput(e) {
        const key = e.which || e.keyCode;

        if (key === KeyCodes.ENTER) {
            this.validateAndSearchSeekers();
        }
    }

    filterSeekerName(searchText) {
        const filterForm = {
            ...this.state.filterForm,
            searchText
        };

        this.setState({ filterForm });
    }

    validateAndSearchSeekers() {
        const { searchText } = this.state.filterForm;
        const { positionId, searchSeekers } = this.props;
        const resetPage = 1;

        if (searchText.length < SEARCH_SEEKER_INPUT_MIN_LENGTH) {
            this.setState({
                validation: {
                    searchText: 'addSeeker.validation.minLength'
                }
            });
        } else {
            this.setState({
                validation: {
                    searchText: null
                },
                paginator: {
                    page: resetPage
                }
            });
            searchSeekers(searchText, positionId, resetPage);
        }
    }

    handleScrollLoadMoreSeekers(e) {
        const isBottom = (e) => e.scrollHeight - e.scrollTop === e.clientHeight;
        const nextPage = this.getCurrentPaginatorPage() + 1;
        const { searchText } = this.state.filterForm;
        const { positionId, onLoadNextPage, nextPageAvailable } = this.props;

        if (isBottom(e.target) && onLoadNextPage === false && nextPageAvailable === true) {
            this.setCurrentPaginatorPage(nextPage);
            this.props.searchSeekers(searchText, positionId, nextPage);
        }
    }

    confirmAddSeeker(seekerId) {
        this.setState({
            confirmAddSeekerId: seekerId
        });
        this.toggleConfirmAddPopup(true);
    }

    toggleConfirmAddPopup(forceShow) {
        let show = !this.state.sweetAlerts.confirmAdd;

        if (forceShow) {
            show = true;
        }

        this.setState({
            sweetAlerts: {
                confirmAdd: show
            }
        });
    }

    renderAllAlerts() {
        const { sweetAlerts, confirmAddSeekerId } = this.state;

        const {
            positionId,
            addSeekerForPosition,
            history,
            dateRange,
            checkSeekerNotToJob
        } = this.props;

        return (
            <Fragment>
                <Modal hideBackdrop open={sweetAlerts.confirmAdd}>
                    <SweetAlert
                        custom
                        customIcon={Warning}
                        btnSize="sm"
                        show
                        showCancel
                        cancelBtnText={<IntlMessages id="button.cancel" />}
                        title=""
                        onConfirm={() => {
                            this.toggleConfirmAddPopup();
                            checkSeekerNotToJob(positionId, confirmAddSeekerId,() => addSeekerForPosition(positionId, confirmAddSeekerId, currentPage(history.location), dateRange) )
                        }}
                        onCancel={() => this.toggleConfirmAddPopup()}
                        confirmBtnCssClass={"btn-orange"}
                        cancelBtnCssClass={"btn-black"}>
                        <FormattedMessage id="addSeeker.confirmAddPopup" />
                    </SweetAlert>
                </Modal>

                <SweetAlert
                    success
                    show={this.props.addSuccess}
                    title=""
                    btnSize="sm"
                    onConfirm={() => {
                        this.props.hideFailurePopup();
                        this.props.forceUpdateParent();
                    }}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}
                >
                    <IntlMessages id="addSeeker.doAdd.success" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.props.addFailure}
                    title=""
                    btnSize="sm"
                    onConfirm={() => this.props.hideFailurePopup()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id={this.props.addSeekerErrorMsg} />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.props.warningSeeker48h}
                    title={<IntlMessages id="addSeeker.doAdd.restriction48hours.title" />}
                    btnSize="sm"
                    onConfirm={() => this.props.hideWarningSeeker48h()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id="addSeeker.doAdd.restriction48hours.message" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.props.addSeekerForOver18}
                    title="Over 18's Only"
                    btnSize="sm"
                    onConfirm={() => this.props.hideFailurePopup()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id="addSeeker.doAdd.over18" />

                </SweetAlert>

                <SweetAlert
                    error
                    show={this.props.warningSeekerStudent}
                    title={<IntlMessages id="components.jobCard.ListSeekerTab.studentExceed.title" />}
                    btnSize="sm"
                    onConfirm={() => this.props.hideWarningSeekerIsStudent()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id="addSeeker.doAdd.exceeded" />
                </SweetAlert>

                <SweetAlert
                    error
                    show={this.props.addGotUnavailable}
                    title=""
                    btnSize="sm"
                    onConfirm={() => this.props.hideFailurePopup()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <IntlMessages id="addSeeker.doAdd.unavailable" />
                </SweetAlert>
                <SweetAlert
                    show={this.props.warningSeekerOverSixDay}
                    custom
                    customIcon={Warning}
                    btnSize="sm"
                    showCancel
                    cancelBtnText={<IntlMessages id="button.cancel" />}
                    title=""
                    onConfirm={() => {
                        this.props.hideWarningWorkingSixDay();
                        addSeekerForPosition(positionId, confirmAddSeekerId, currentPage(history.location), dateRange);
                    }}
                    onCancel={() => this.props.hideWarningWorkingSixDay()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <FormattedMessage
                        id="addSeeker.doAdd.working6Day"
                    />
                </SweetAlert>

                <SweetAlert
                    show={this.props.warningSeekerIn8h}
                    custom
                    customIcon={Warning}
                    btnSize="sm"
                    showCancel
                    cancelBtnText={<IntlMessages id="button.cancel" />}
                    title=""
                    onConfirm={() => {
                        this.props.hideWarningSeekerIn8hours();
                        addSeekerForPosition(positionId, confirmAddSeekerId, currentPage(history.location), dateRange);
                    }}
                    onCancel={() => this.props.hideWarningSeekerIn8hours()}
                    confirmBtnCssClass={"btn-orange"}
                    cancelBtnCssClass={"btn-black"}>
                    <FormattedMessage
                        id="addSeeker.doAdd.in8h"
                    />
                </SweetAlert>

            </Fragment>
        );
    }

    render() {
        const { addedListIds, filterForm, validation } = this.state;
        const { listSeekersForAdd, onLoadNextPage, onLoading, onAdding, history } = this.props;
        const isCalendarPage = currentPage(history.location) === MyGig.currentPage.calendarPage;

        return (
            <div className="nudge-seeker-outer-wrapper">
                <SwipeableDrawer
                    id="modal-add-right"
                    anchor={'right'}
                    open={this.props.showAddSeeker}
                    onOpen={() => false}
                    onClose={() => this.handleCloseModal()}>
                    <div className="drawer-wrapper">
                        <Spinner
                            spinnerWidth={70}
                            show={onLoading || onAdding}
                        />

                        <div
                            className="nudge-seeker-inner-wrapper"
                            onScroll={(e) => this.handleScrollLoadMoreSeekers(e)}
                        >
                            <h1 className="nudge-seeker-title black thin">
                                <IntlMessages id="addSeeker.title" />
                                <i
                                    className="zmdi zmdi-close-circle orange font-2x pull-right on-hover"
                                    onClick={() => this.handleCloseModal()}
                                />
                            </h1>

                            <div className="nudge-filter-form">
                                <div className="row">
                                    <FormGroup className="col-sm-5 col-xs-12">
                                        <InputGroup>
                                            <FormattedMessage id="nudgeSeeker.placeholders.searchInput">
                                                {(placeholderText) => (
                                                    <Input
                                                        placeholder={placeholderText}
                                                        value={filterForm.searchText}
                                                        onChange={(e) => this.filterSeekerName(e.target.value)}
                                                        onKeyUp={(e) => this.handleKeyupSearchInput(e)}
                                                    />
                                                )}
                                            </FormattedMessage>

                                            <InputGroupAddon addonType="append">
                                                <i
                                                    className="material-icons"
                                                    onClick={() => this.validateAndSearchSeekers()}
                                                >
                                                    search
                                                </i>
                                            </InputGroupAddon>
                                        </InputGroup>

                                        {!!validation.searchText && (
                                            <FormHelperText className="field-error">
                                                <FormattedMessage id={validation.searchText} />
                                            </FormHelperText>
                                        )}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="nudge-seeker-lists">
                                {!onLoading && listSeekersForAdd.length === 0 && (
                                    <div className="font-bold center vertical-space-small">
                                        <FormattedMessage id="addSeeker.empty.searchResult" />
                                    </div>
                                )}

                                <ListSeekers
                                    listSeekers={listSeekersForAdd}
                                    addedListIds={addedListIds}
                                    isNudge
                                    isAddSeekerButton
                                    onSubmitAddSeeker={(seekerId) => this.confirmAddSeeker(seekerId)} />
                            </div>

                            {onLoadNextPage && (
                                <div className="pd-10 center">
                                    <CircularProgress
                                        className="progress-warning"
                                        size={40}
                                        mode="determinate"
                                        value={40}
                                        min={0}
                                        max={50}
                                    />
                                </div>
                            )}
                        </div>
                        {isCalendarPage && this.renderAllAlerts()}
                    </div>
                </SwipeableDrawer>
                {!isCalendarPage && this.renderAllAlerts()}
            </div>
        );
    }
}

const mapStateToProps = ({ addSeekerReducer, searchSeekerReducer, positionReducer }) => ({
    ...searchSeekerReducer,
    showAddSeeker: addSeekerReducer.showAddSeeker,
    lastAddedId: addSeekerReducer.lastAddedId,
    onAdding: addSeekerReducer.onAdding,
    addSuccess: addSeekerReducer.addSuccess,
    addFailure: addSeekerReducer.addFailure,
    addGotExceeded: addSeekerReducer.addGotExceeded,
    addGotUnavailable: addSeekerReducer.addGotUnavailable,
    addSeekerForOver18: addSeekerReducer.addSeekerForOver18,
    addSeekerErrorMsg: addSeekerReducer.addSeekerErrorMsg,
    warningSeekerOverSixDay: positionReducer.warningSeekerOverSixDay,
    listSeekerWarningSixDay: positionReducer.listSeekerWarningSixDay,
    loadingCheckSeekerSixDay: positionReducer.loadingCheckWarningSixDay,
    warningSeeker48h: positionReducer.warningSeeker48h,
    warningSeekerStudent: positionReducer.warningSeekerStudent,
    warningSeekerIn8h:  positionReducer.warningSeekerIn8h
});

const mapActionToProps = {
    hideAddSeekerModal,
    addSeekerForPosition,
    hideFailurePopup,
    searchSeekers,
    searchSeekersResetResults,
    checkWorkingSixDay,
    hideWarningWorkingSixDay,
    checkSeekerNotToJob,
    hideWarningSeeker48h,
    hideWarningSeekerIsStudent,
    hideWarningSeekerIn8hours
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(AddSeeker));
